<template>
  <!-- <a href="#" @click.prevent="onClick">{{$getFileName(path)}}</a> -->
  <a :href="path" target="_blank" download>{{ $getFileName(path) }}</a>
</template>

<script>
// import download from '@/api/download';
// import { saveAs } from 'file-saver'
export default {
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  // methods: {
  //     onClick() {
  //         download({
  //             url: this.path,
  //         }).then((blob) => {
  //             saveAs(blob, this.$getFileName(this.path));
  //         })
  //     }
  // }
};
</script>