<template>
  <div>
    <a-config-provider :locale="zh_CN">
      <router-view />
    </a-config-provider>
  </div>
</template>


<script>
import zh_CN from "ant-design-vue/es/locale/zh_CN";
import { mapMutations, mapState } from "vuex";

import { Boot } from "@wangeditor/editor";
import formulaModule from "@wangeditor/plugin-formula";
Boot.registerModule(formulaModule);

export default {
  data() {
    return {
      zh_CN: Object.freeze(zh_CN),
    };
  },

  methods: {
    ...mapMutations("auth", ["setUser", "setMenuList", "setMenu"]),

    ...mapMutations("employeeSelector", [
      "setRecentSelectedList",
      "setSortType",
    ]),

    ...mapMutations("tabs", ["setTabList"]),
  },

  computed: {
    ...mapState("tabs", ["tabList"]),
  },

  mounted() {
    if (process.env.NODE_ENV === "development") {
      document.title = "【开发环境】瓴云数字化云平台";
    }

    // 生产环境，用户如果打开了需要填写的表单页面，关闭或刷新网页时加提示
    if (process.env.NODE_ENV === "production") {
      window.addEventListener("beforeunload", (event) => {
        const routes = this.$router.getRoutes(); // 获取所有的路由记录

        let flag = false;

        this.tabList.forEach((tab) => {
          const obj = routes.find((item) => item.path === tab.fullPath);
          if (obj && obj.meta && obj.meta.alertWhenLeaving) {
            flag = true;
          }
        });

        console.log("flag", flag);

        if (flag) {
          // Cancel the event as stated by the standard.
          event.preventDefault();
          // Chrome requires returnValue to be set.
          event.returnValue = "";
        }
      });
    }

    // 1. 恢复用户基本信息
    const user = window.localStorage.getItem("user");
    if (user) {
      this.setUser(JSON.parse(user));
    }

    // 3. 恢复员工选择器中的最近选择
    const recentSelectedList = window.localStorage.getItem(
      "recent-selected-list"
    );
    if (recentSelectedList) {
      this.setRecentSelectedList(JSON.parse(recentSelectedList));
    }

    // 恢复员工选择器部门排序
    const deptSortType = window.localStorage.getItem("dept-sort-type");
    if (deptSortType) {
      this.setSortType(deptSortType);
    }

    const tabList = window.localStorage.getItem("tabList");
    if (tabList) {
      this.setTabList(JSON.parse(tabList));
    }

    // 用户登录的情况下，刷新页面，恢复左侧菜单数据
    const menuList = window.localStorage.getItem("menuList");
    if (menuList) {
      this.setMenuList(JSON.parse(menuList));
    }
    const menu = window.localStorage.getItem("menu");
    if (menu) {
      this.setMenu(JSON.parse(menu));
    }
  },
};
</script>