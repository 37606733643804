// 获取当前时间
export function getNow() {
    const now = new Date();
    const year = now.getFullYear();
    let month = now.getMonth() + 1;
    let date = now.getDate();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();
    if (month < 10) {
        month = '0' + month
    }
    if (date < 10) {
        date = '0' + date;
    }
    if (hours < 10) {
        hours = '0' + hours;
    }
    if (minutes < 10) {
        minutes = '0' + minutes
    }
    if (seconds < 10) {
        seconds = '0' + seconds;
    }
    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`
}

export function getDateString(str) {
    return typeof str === 'string' ? str.split(' ')[0] : ''
}