export function getFileName(path) {
    if (typeof path === 'string') {
        const index = path.indexOf('_')
        if (index > -1) {
            let str =  path.substring(index + 1)
            return str.split('?')[0]
        } else {
            return ''
        }
    } else {
        return ''
    }
}