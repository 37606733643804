import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Pane from '@/components/pane'
import Padding from '@/components/padding'
import PrivateButton from '@/components/private-button'
import PermissionButton from '@/components/permission-button'
import DataDictFinder from '@/components/data-dict-finder'
import Money from '@/components/money'
import Download from '@/components/download'

import './css/index.less'

import Print from 'vue-print-nb'

import {
  Button, Menu, Layout, Icon, Card, Table, Tabs, Form,
  Row, Col, Alert, Input, Checkbox, Select, Modal, Descriptions,
  Space, Divider, Dropdown, Badge, Avatar, Tree, message,
  Result, Upload, Progress, Breadcrumb, Statistic, List,
  Steps, Radio, InputNumber, Tooltip, ConfigProvider, FormModel,
  Empty, Pagination, DatePicker, TimePicker, Tag, Timeline,
  Carousel, Transfer, Cascader, Skeleton, Slider, Spin,
  Switch, Popconfirm, Popover, AutoComplete
} from 'ant-design-vue'

// 常用方法
import encrypt from './utils/encrypt'
import { copy } from './utils/clipboard'
import { getPermission } from '@/utils/permission'
import { imgCrop } from '@/utils/image'
import { close } from '@/utils/cache'
import { selectFilterOption, ratioFormatter, inputNumberFormat } from '@/utils/antdv'
import { getDateString } from '@/utils/time'
import { getFileName } from '@/utils/file'

import { numberFormat } from '@/utils/num'

// 精确计算方法
import accurate from "accurate";
Vue.prototype.$calc = accurate

Vue.config.productionTip = false

Vue
  .use(Button).use(Menu).use(Layout).use(Icon).use(Card).use(Table)
  .use(Tabs).use(Form).use(Row).use(Col).use(Alert).use(Input).use(Checkbox)
  .use(Select).use(Modal).use(Descriptions).use(Space).use(Divider).use(Dropdown)
  .use(Badge).use(Avatar).use(Tree).use(Result).use(Upload).use(Progress)
  .use(Breadcrumb).use(Statistic).use(List).use(Steps).use(Radio)
  .use(InputNumber).use(Tooltip).use(ConfigProvider).use(FormModel)
  .use(Empty).use(Pagination).use(DatePicker).use(TimePicker).use(Tag).use(Timeline)
  .use(Carousel).use(Transfer).use(Cascader).use(Skeleton).use(Slider).use(Spin)
  .use(Switch).use(Popconfirm).use(Popover).use(AutoComplete)
  .use(Print)

Vue.prototype.$message = message;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$error = Modal.error;
Vue.prototype.$success = Modal.success;
Vue.prototype.$info = Modal.info;


Vue.component('Pane', Pane) // 页面的头部面板
Vue.component('PrivateButton', PrivateButton) // 页面内的权限按钮
Vue.component('PermissionButton', PermissionButton) // 页面内的权限按钮
Vue.component("Padding", Padding) // 页面常用的间距组件
Vue.component('DataDictFinder', DataDictFinder) // 数据字典查找器
Vue.component("Money", Money)
Vue.component('Download', Download)

Vue.prototype.$getPermission = getPermission;
Vue.prototype.$encrypt = encrypt;
Vue.prototype.$copy = copy;
Vue.prototype.$imgCrop = imgCrop;
Vue.prototype.$close = close
Vue.prototype.$selectFilterOption = selectFilterOption;
Vue.prototype.$ratioFormatter = ratioFormatter;
Vue.prototype.$inputNumberFormat = inputNumberFormat;
Vue.prototype.$getDateString = getDateString;
Vue.prototype.$getFileName = getFileName;
Vue.prototype.$numberFormat = numberFormat;
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
